<template>
    <dropdown-hover
        class="group h-12"
        :class="
            isAdminArea
                ? 'hover:bg-background-lighter p-2 bg-background'
                : 'bg-background-lighter pl-4 pr-1.5 hover:shadow-menu'
        "
        :opens-towards="isAdminArea ? 'right' : 'bottom'"
    >
        <template #button>
            <burger-icon
                v-if="!isAdminArea"
                class="mr-2 group-hover:text-hover"
                :class="
                    !isAdminArea &&
                    (currentRouteName.includes('setting') || currentRouteName.includes('favorites')
                        ? 'text-primary'
                        : 'text-gray')
                "
            />
            <profile-image />
        </template>
        <template #content>
            <div class="flex flex-col gap-2.5">
                <router-link
                    v-if="hasActiveLicense && !isAdminArea"
                    class="flex items-center hover:text-hover"
                    :to="{name: 'FavoriteSeats'}"
                >
                    <span v-text="__('app.header.fav_seats')" />
                    <heart-solid-icon class="ml-2 !h-2 text-accent dark:bg-white" />
                </router-link>
                <router-link
                    v-if="hasActiveLicense && !isAdminArea"
                    class="hover:text-hover"
                    :to="{name: 'UserSettings'}"
                >
                    <span v-text="__('app.header.settings')" />
                </router-link>
                <router-link v-if="!disableLogout" class="hover:text-hover" :to="{name: 'Logout'}">
                    <span v-text="__('app.header.logout')" />
                </router-link>
                <hr class="text-gray" />
                <a :href="`https://www.desk.ly/${currentLocale}/privacy`" target="_blank">
                    <button-link black is-external :text="__('app.header.privacy_policy')" />
                </a>
                <a :href="`https://www.desk.ly/${currentLocale}/help`" target="_blank">
                    <button-link black is-external :text="__('app.header.help')" />
                </a>
            </div>
        </template>
    </dropdown-hover>
</template>

<script>
import ButtonLink from '../../core/ButtonLink.vue';
import HeartSolidIcon from '../../icons/HeartSolidIcon.vue';
import ProfileImage from './ProfileImage.vue';
import BurgerIcon from '../../icons/BurgerIcon.vue';
import DropdownHover from '../../core/DropdownHover.vue';
import {useLocaleStore} from '../../../../stores/localeStore';
import {mapState} from 'pinia';
import {useUserStore} from '../../../../stores/userStore';

export default {
    components: {
        DropdownHover,
        BurgerIcon,
        ButtonLink,
        ProfileImage,
        HeartSolidIcon,
    },
    props: {
        disableLogout: Boolean,
        isAdminArea: Boolean,
    },
    computed: {
        ...mapState(useLocaleStore, ['currentLocale']),
        ...mapState(useUserStore, ['hasActiveLicense']),
        currentRouteName() {
            return this.$route.path;
        },
    },
};
</script>
