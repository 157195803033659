import {defineStore} from 'pinia';
import {syncRefs, useLocalStorage} from '@vueuse/core';
import {computed} from 'vue';

const currentLocaleStorageKey = 'currentLocale';

function getLocale(storageLocale) {
    if (storageLocale && getAvailableLanguages().includes(storageLocale)) {
        return storageLocale;
    }

    const preferredLocale = navigator.language.substr(0, 2).toLowerCase();

    if (getAvailableLanguages().includes(preferredLocale)) {
        return preferredLocale;
    }

    return 'en';
}

/**
 * Gets the current valid locale from either local storage or the preferred user language.
 *
 * This method should only be used outside any Vue context.
 * Within a Vue context, `useLocaleStore` should be used.
 * @returns {string}
 */
export function getCurrentLocale() {
    const storageLocale = localStorage.getItem(currentLocaleStorageKey);
    return getLocale(storageLocale);
}

function getAvailableLanguages() {
    return window.availableLanguages ?? [];
}

export const useLocaleStore = defineStore('locale', () => {
    /**
     * The locale which is set within local storage.
     * Can potentially not be contained within `availableLanguages`.
     * @type {import('vue').Ref<string>}
     */
    const storageLocale = useLocalStorage(currentLocaleStorageKey, null);

    /**
     * The current, valid locale.
     * @type {import('vue').ComputedRef<string>}
     */
    const currentLocale = computed(() => getLocale(storageLocale.value));

    syncRefs(currentLocale, storageLocale);

    /**
     * Sets the new target locale, if it is found
     * within the available languages.
     * @param {string} locale Target locale.
     * @returns {void}
     */
    function updateLocale(locale) {
        if (getAvailableLanguages().indexOf(locale) === -1) {
            return;
        }

        storageLocale.value = locale;
    }

    /**
     * Languages for which translations are available
     * @type {[String]}
     */
    const availableLanguages = window.availableLanguages;

    return {currentLocale, storageLocale, availableLanguages, updateLocale};
});
